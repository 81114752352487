


//COMPONENTS

import React, { Component } from 'react';
import Aux from '../../Hook/Auxiliary/index'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import otpIcon from '../../../img/icons/otpIcon.png'
import notRobot from '../../../img/logo/im-not-a-robot.png';
import $ from "jquery";

//COMPONENTS END

//IMG START



class Checkout extends Component {
    constructor() {
        super();
        this.toggleNav = this.toggleNav.bind(this);
    }

    toggleNav() {
        var checkedMenu = $(".radio-wrp");
        var radio1Wrapper = $(".option1");
        var radio2Wrapper = $(".option2");
        var radio3Wrapper = $(".option3");

        var radio1 = $("#flexRadioDefault1");
        var radio2 = $("#flexRadioDefault2");
        var radio3 = $("#flexRadioDefault3");
        var menuActive = "border-global-stronger";


        $(checkedMenu).click(function () {


            if ($(radio1).is(':checked')) {
             
                    $(radio1Wrapper).addClass(menuActive);
                    if ($(radio2Wrapper).hasClass(menuActive)) {
                        $(radio2Wrapper).removeClass(menuActive);
                    }
                    else if ($(radio3Wrapper).hasClass(menuActive)) {
                        $(radio3Wrapper).removeClass(menuActive);
                    }
            }

            else if ($(radio2).is(':checked')) {
             
                $(radio2Wrapper).addClass(menuActive);
                if ($(radio1Wrapper).hasClass(menuActive)) {
                    $(radio1Wrapper).removeClass(menuActive);
                }
                else if ($(radio3Wrapper).hasClass(menuActive)) {
                    $(radio3Wrapper).removeClass(menuActive);
                }
        }

        else if ($(radio3).is(':checked')) {
             
            $(radio3Wrapper).addClass(menuActive);
            if ($(radio1Wrapper).hasClass(menuActive)) {
                $(radio1Wrapper).removeClass(menuActive);
            }
            else if ($(radio2Wrapper).hasClass(menuActive)) {
                $(radio2Wrapper).removeClass(menuActive);
            }
    }


        });





    };


    componentDidMount() {
        this.toggleNav()
    }
    render() {




        return (
            <Aux>

                <section className="section section--title section-title--generic w-out-padding-bottom">
                    <div className="container container-xl">
                        <div className="row section-content__wrapper">
                            <div className="col-12 col-md-6">
                                <h1 className="checkout-title">SZÁMLÁZÁSI ADATOK</h1>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                                    doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                                    veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section checkout-section pt-0 margint-top-general">
                    <div className="container container-xl">
                        <div className="row ">
                            <div className="col-12 col-md-8">
                                <form>

                                    <div className="form-group email-row">
                                        <div>


                                            <label for="inputEmail4">E-mail címed<span className="star-sd-color">*</span></label>
                                            <span> <input type="email" className="form-control" id="inputEmail4" placeholder="info@email.hu" /><span className="warning-icon-input-first icon-war"><FontAwesomeIcon icon={faExclamationCircle} /></span></span>
                                        </div>
                                    </div>
                                    <div className="form-group email-row">
                                        <div>
                                            <label for="inputEmail4">E-mail címed megerősítése<span className="star-sd-color">*</span></label>
                                            <span> <input type="email" className="form-control" id="inputEmail4" placeholder="info@email.hu" /><span className="warning-icon-input-second icon-war"><FontAwesomeIcon icon={faExclamationCircle} /></span></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label for="exampleInputEmail1">Számlázási név<span className="star-sd-color">*</span></label>
                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                    <div className="form-group">
                                        <label for="exampleInputEmail1">Cégnév</label>
                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col col-12 col-sm-6">
                                            <label for="exampleFormControlSelect1">Ország<span className="star-sd-color">*</span></label>
                                            <select className="form-select" id="exampleFormControlSelect1">
                                                <option value="" disabled selected>Válaszd ki az országodat!</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                        <div className="col col-12 col-sm-6">
                                            <label for="exampleInputPassword1">Állam / Megye<span className="star-sd-color">*</span></label>
                                            <select className="form-select">
                                                <option value="" disabled selected>Válaszd ki a régiódat!</option>
                                                <option>Default select</option>
                                                <option>Default select</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col col-12 col-sm-6">
                                            <label for="exampleInputPassword1">Város<span className="star-sd-color">*</span></label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div className="col col-12 col-sm-6">
                                            <label for="exampleInputPassword1">Irányítószám<span className="star-sd-color">*</span></label>
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col col-12 col-sm-6">
                                            <label for="exampleInputPassword1">Utcanév<span className="star-sd-color">*</span></label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div className="col col-12 col-sm-6">
                                            <div className="form-row">
                                                <div className="col col-12 col-md-8 col-sm-8">
                                                    <label for="exampleInputPassword1">Utca jellege<span className="star-sd-color">*</span></label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                                <div className="col col-12 col-md-4 col-sm-4">
                                                    <label for="exampleInputPassword1">Házszám<span className="star-sd-color">*</span></label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col col-12 col-sm-6">
                                            <label for="exampleInputPassword1">Háztömb</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div className="col col-12 col-sm-6">
                                            <div className="form-row">
                                                <div className="col col-12 col-md-4 col-sm-4">
                                                    <label for="exampleInputPassword1">Bejárat</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                                <div className="col col-12 col-md-4 col-sm-4">
                                                    <label for="exampleInputPassword1">Emelet</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                                <div className="col col-12 col-md-4 col-sm-4">
                                                    <label for="exampleInputPassword1">Ajtó</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="section-content__wrapper margint-top-general">
                                        <div className="w-100">


                                            <div className="checkout-section__selected-tickets boxed boxed--selected-tickets boxed--border boxed--shadow boxed--style1">

                                                {/* header */}
                                                <div className="box-header bg--dark">
                                                    <span className="table-title">

                                                        <h3 className="checkout-subtitle text-left">Kiválasztott jegyek</h3>
                                                    </span>


                                                </div>

                                                {/* box content */} 
                                                <div className="boxed-content no-padding">
                                                    <table className="selected-tickets__table boxed-content__table table--inner-borders table--gridtable">
                                                        <thead>
                                                            <tr>
                                                                <td className="ticket-type-col">Járat</td>
                                                                <td className="ticket-price-col">Összeg</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="selected-tickets__item ticket-type-col">
                                                                <td className="selected-tickets__type">
                                                                    <span className="type__title">Capuccino járat</span>
                                                                    <span className="type__date">2021. július 12. Vasárnap</span>
                                                                    <ul className="type__list list list-style--elegant">
                                                                        <li>Felnőtt – 11:00</li>
                                                                    </ul>
                                                                </td>
                                                                <td className="selected-tickets__price ticket-price-col">3.200 Ft</td>
                                                            </tr>
                                                            <tr className="selected-tickets__item ticket-type-col">
                                                                <td className="selected-tickets__type">
                                                                    <span className="type__title">Capuccino járat</span>
                                                                    <span className="type__date">2021. július 12. Vasárnap</span>
                                                                    <ul className="type__list list list-style--elegant">
                                                                        <li>Felnőtt – 11:00</li>
                                                                    </ul>
                                                                </td>
                                                                <td className="selected-tickets__price ticket-price-col">3.200 Ft</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan="2">Végösszeg: <b>6.400 Ft</b></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                                {/* END OF box content */} 

                                                {/* <div className="boxed-content d-flex">
                                                    <div className="main-col w-75">
                                                        <div className="general-border-right-bottom general-height-smaller parent-element"><h3 className="element bolder-texts-general general-orint-middle">JÁRAT</h3></div>
                                                        <div className="general-border-right">
                                                            <div className="ticket-descripton general-height-bigger">
                                                                <div className="ticket-descripton-wrapper">
                                                                    <h6 className="cruise-type">Hűsítő járat</h6>
                                                                    <p className="cruise-type-desc">2021. július 12. Hétfő </p>
                                                                    <div className="d-flex ">
                                                                        <span className="ticket-type cruise-type-desc">Felnőtt jegy - </span><span className="text-date cruise-type-desc"> 11:30</span>

                                                                    </div>
                                                                </div>

                                                            </div></div>

                                                    </div>
                                                    <div className="sub-col w-25">

                                                        <div className="general-border-left-bottom general-height-smaller parent-element"><h3 className="element bolder-texts-general general-orint-middle">ÖSSZEG</h3></div>
                                                        <div className="general-border-left general-height-bigger parent-element"><h6 className="element general-orint-middle">3.200 HUF</h6>
                                                        </div>


                                                    </div>
                                                </div> */}

                                                {/* <div className="boxed-content d-flex general-border-top">
                                                    <div className="main-col w-75">

                                                        <div className="general-height-smaller parent-element"><h3 className="element general-orint-right title-bigger">VÉGÖSSZEG:</h3></div>
                                                    </div>
                                                    <div className="sub-col w-25">


                                                        <div className="general-height-smaller parent-element"><h3 className="element general-orint-middle title-bigger">3.200 HUF</h3>
                                                        </div>
                                                    </div>
                                                </div> */}

                                            </div>
                                        </div></div>

                                    <div className="radio-container margint-top-general">
                                        <div className="form-check border-global-lighter option1">
                                            <div className="radio-wrapper">
                                                <input className="form-check-input radio-wrp col- g-top" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />



                                                <span><img src={otpIcon}></img></span><label className="form-check-label form-check-label--otp" for="flexRadioDefault1">
                                                    SIMPLEPAY BANKKÁRTYÁS FIZETÉS
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-check border-global-lighter option2">
                                            <div className="radio-wrapper">
                                             <input className="form-check-input radio-wrp col- g-top" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                                <label className="form-check-label" for="flexRadioDefault2">
                                                    FIZETÉS SZÉP KÁRTYÁVAL
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-check border-global-lighter option3">
                                            <div className="radio-wrapper">
                                            <input className="form-check-input radio-wrp col- g-top" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                                <label className="form-check-label" for="flexRadioDefault3">
                                                    FIZETÉS A HELYSZÍNEN
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="additional-info margint-top-general">
                                        <p className="additional-title"><strong>Fontos!</strong></p>
                                        <p className="additional-text">A hajó indulása előtt <strong>minimum 1 órával</strong> foglalhatsz jegyet online. Online vásárolt jegyeddel <strong>minimum 20 perccel a járat
                                            indulása előtt</strong> jelenj meg a hajónál. Késés esetén a jegy(ek) árát nem áll módunkban visszatéríteni.
                                        </p>
                                    </div>


                                    <div className='d-flex row margint-top-general aszf'>
                                        <div className="col-md-7 col-12 first-col">
                                            <div className="form-check">
                                                <input className="form-check-input col-" type="checkbox" value="" id="flexCheckDefault" />
                                                <label className="form-check-label" for="flexCheckDefault">
                                                    Elolvastam és elfogadom az <a className="files"> Adatkezelési Tájékoztatót.</a>
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input col-" type="checkbox" value="" id="flexCheckChecked" checked />
                                                <label className="form-check-label" for="flexCheckChecked">
                                                    Elolvastam és elfogadom az <a className="files"> Általános Szerződési Feltételeket.</a>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-5 col-sm-7 col-8 second-col">
                                            <img src={notRobot}></img>
                                        </div>
                                    </div>

                                    <div className="add-info margint-top-general">
                                        <span class="icon-war"> <FontAwesomeIcon icon={faExclamationCircle} /></span> <span className="add-text">Egy vagy több kötelező mező hibásan, vagy nem lett kitöltve. Kérünk, ellenőrizd minden bevitt adatod!</span>
                                    </div>

                                    <div class="d-flex justify-content-between btn-wrapper margint-top-general">
                                        <span className="left-btn"><FontAwesomeIcon icon={faArrowLeft} /> <a className="back-btn">VISSZA</a></span>
                                        <button type="submit" className="sender btn col- w-25">FIZETÉS <FontAwesomeIcon icon={faChevronRight} /></button>

                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </section>


            </Aux>



        )
    }
}

export default Checkout;