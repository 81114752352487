//COMPONENTS START
import React, { Component } from 'react';
//COMPONENTS END

//IMGS START
import kisfaludy_logo_multicolored from '../../../img/logo/2x/kisfaludy_logo_multicolored-2x.png';
import fbIcon from '../../../img/icons/fb-icon.svg'
import simplepay_ft_logo from '../../../img/ft_logo_simplepay2.png';
//IMGS END

//FILES
import Pdf_adatvedelmi from '../../../assets/files/adatvedelmi_tajekoztato.pdf';
import Pdf_aszf from '../../../assets/files/aszf.pdf';

class Footer extends Component {


    render() {

        return (

            <footer id="footer" className="footer--ticket-pages bg--dark texture-dark text-center-xs text-left space--xs">
                <div className="footer__bg-container">
                    <span className="footer__waves wave--left"></span>
                    <span className="footer__waves wave--right"></span>
                </div>
                <div className="container container-xl">

                    <div className="row align-items-start justify-content-center justify-content-md-center">

                        <div className="col-12 col-md-3 pb-5 pb-md-0 align-self-stretch">

                            <div className="footer__logo__wrapper">

                                <img src={kisfaludy_logo_multicolored} className="logo" alt="Kisfaludy Logó" />


                            </div>

                        </div>

                        <div className="col-12 text-center text-md-left col-md-4 col-xl-3">
                            <ul className="list footer__nav hover-wrapper">
                                <h6 className="footer-col-titles">NAVIGÁCIÓ</h6>
                                <hr className="hr-st-margin"></hr>



                                <li className="menu-items-hover"><a className="menu-items-hover" href="https://kisfaludygozos.hu/kisfaludybar" >Kisfaludy Bár</a></li>

                                <li className="menu-items-hover"><a className="menu-items-hover" href="https://kisfaludygozos.hu/#menetrendjegyarak" >Menetrend és jegyárak</a></li>


                                <li className="menu-items-hover"><a className="menu-items-hover" href="https://kisfaludygozos.hu/#terkep">Térkép</a></li>


                                <li className="menu-items-hover"><a className="menu-items-hover" href="https://kisfaludygozos.hu/#gyik">Egyéb tudnivalók</a></li>

                                <li className="menu-items-hover"> <a href="#start">Vissza az oldal tetejére</a></li>
                            </ul>
                        </div>

                        <div className="col-12 text-center text-md-left col-md-4 col-xl-3">

                            <ul className="list footer__nav hover-wrapper">
                                <h6 className="footer-col-titles">RENDEZVÉNYEK</h6>
                                <hr className="hr-st-margin"></hr>

                                <li><a className="menu-items-hover" href="https://kisfaludygozos.hu/ceges-rendezvenyek">Céges rendezvény</a></li>


                                <li><a className="menu-items-hover" href="https://kisfaludygozos.hu/csaladi-rendezvenyek">Születésnap</a></li>


                                <li><a className="menu-items-hover" href="https://kisfaludygozos.hu/foto-es-forgatas">Reklám- és fotóhelyszín</a></li>


                                <li><a className="menu-items-hover" href="https://kisfaludygozos.hu/eskuvo-rendezvenyek">Esküvői ceremónia</a></li>

                            </ul>
                        </div>

                        <div className="col-12 text-center text-md-left col-md-4 col-xl-3">
                            <ul className="list footer__nav last-col">
                                <h6 className="footer-col-titles">ELÉRHETŐSÉG</h6>
                                <hr className="hr-st-margin"></hr>
                                <div className="row">
                                    <div className="col-12 py-4 py-md-0">
                                        <ul className="list-inline list-contact">

                                            <a href="https://www.facebook.com/kisfaludygozos" target="_blan" rel="noreferrer">
                                                <li className="fb-icon-wrapper"><img className="fb-icon" src={fbIcon} alt="facebook-icon" />Facebook</li>
                                            </a>
                                        </ul>
                                    </div>
                                </div>
                            </ul>
                            <div className="row credits-area">
                                <div className="col-12 left-padding">
                                    <span className="type--fine-print credits-area__wrapper">
                                        <span className="block">Balatoni Sétahajózás Kft. |<a className="hover-darker" target="_blank" rel="noreferrer" href="http://isf.hu"
                                        > Made by ISF.hu</a></span>
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 left-padding">
                                    <span className="type--fine-print">
                                        <span><a target="_blank" rel="noreferrer" className="type--fine-print pdf-hover-footer" href={Pdf_adatvedelmi}
                                        >Adatvédelmi nyilatkozat | </a></span>
                                        <span><a target="_blank" rel="noreferrer" className="type--fine-print pdf-hover-footer" href={Pdf_aszf}
                                        >ÁSZF</a></span>
                                    </span><br />
                                    <span className="all-right-reserved">Minden jog fenntartva!</span>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="footer__simplepay-container">
                                <a href="#" target="_blank"><img src={simplepay_ft_logo}/></a>
                            </div>
                        </div>
                    </div>

                </div>

            </footer>

        )
    }

}

export default Footer;