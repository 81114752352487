


//COMPONENTS

import React, { Component } from 'react';
import Aux from '../../Hook/Auxiliary/index'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

//COMPONENTS END

class Cart extends Component {
    constructor() {
        super();

    }


    render() {




        return (
            <Aux>

                {/* Title section */}
                <section className="section section--title section-title--generic cart-page__title">
                    <div className="container container-xl">
                        <div className="row section-content__wrapper">
                            <div className="col-12 col-md-6">
                                <h1>Kosár</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed volutpat velit sed libero tincidunt, a porttitor enim laoreet. Duis eget nibh eu lectus molestie mattis. Mauris eu orci odio. </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* END of Title section */}


                <section className="section section--cruise-types section--cart">
                    <div className="container container-xl">
                        <div className="row section-content__wrapper">
                            <div className="col-12 cartlist-container">

                                {/* Cruise type item -- Capuccino */}
                                <div className="cruise-types__item boxed boxed--cruise-type boxed--border boxed--shadow boxed--style1">

                                    {/* header */}
                                    <div className="box-header bg--dark">
                                        <span className="cruise-type__title">
                                            <img className="icon icon-xl opacity-100" />
                                            <h3>Kiválasztott jegyek</h3>
                                        </span>

                                    </div>

                                    {/* content */}
                                    <div className="boxed-content">
                                        <table className="boxed-content__table table--gridtable">
                                            <thead>
                                                <tr className="table__head">
                                                    <td className="table__ticket-type-col">Járat</td>
                                                    <td className="table__ticket-price-col">ár</td>
                                                    <td className="table__btn-col">darab</td>
                                                    <td className="table__btn-col">összeg</td>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {/* Felnőtt */}
                                                <tr>
                                                    <td className="cartlist__ticket">
                                                        <h4 className="ticket__type">Capuccino járat</h4>
                                                        <span className="ticket__date">2021. június 12. Vasárnap</span>
                                                        <ul className="ticket__items box-table__list">
                                                            <li>Felnőtt jegy — 11:00</li>
                                                        </ul>
                                                    </td>
                                                    <td className="table-cell__price table-cell__price__lighter">2.800 Ft</td>
                                                    <td className="table-cell__btn cartlist__quantity">
                                                        <div className="numselector__number-section">
                                                            <span className="number-section__minus">
                                                                <FontAwesomeIcon icon={faMinus} className=""/>
                                                            </span>
                                                            <span className="number-section__number">1</span>
                                                            <span className="number-section__plus">
                                                                <FontAwesomeIcon icon={faPlus} className=""/>
                                                            </span>

                                                        </div>
                                                    </td>
                                                    <td className="table-cell__price">
                                                        2.800FT
                                                    </td>

                                                </tr>

                                                {/* Gyermek */}
                                                <tr>
                                                    <td className="cartlist__ticket">
                                                        <h4 className="ticket__type">Capuccino járat</h4>
                                                        <span className="ticket__date">2021. június 12. Vasárnap</span>
                                                        <ul className="ticket__items box-table__list">
                                                            <li>Gyerek jegy — 11:00</li>
                                                        </ul>
                                                    </td>
                                                    <td className="table-cell__price table-cell__price__lighter">1.500 Ft</td>
                                                    <td className="table-cell__btn cartlist__quantity">
                                                        <div className="numselector__number-section">
                                                            <span className="number-section__minus">
                                                                <FontAwesomeIcon icon={faMinus} />
                                                            </span>
                                                            <span className="number-section__number">1</span>
                                                            <span className="number-section__plus">
                                                                <FontAwesomeIcon icon={faPlus} />
                                                            </span>

                                                        </div>
                                                    </td>
                                                    <td className="table-cell__price">
                                                        1.500FT
                                                    </td>
                                                </tr>



                                            </tbody>
                                            <tfoot>
                                                <tr>




                                                    <td className="btn-refresh-td" colspan="4"><div className="col-12 btn-refresh-wrapper"><button className="refresh-btn btn">KOSÁR FRISSÍTÉSE<FontAwesomeIcon icon={faHistory}></FontAwesomeIcon></button></div></td>




                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    {/* END OF content */}
                                </div>

                            </div>
                        </div></div>

                        <div className="container container-xl">
                        <div className="section-content__wrapper result-item ">
                            <div className="col-lg-4 col-12 boxed boxed--shadow">
                                <div className="justify-content-between row">
                                    <div className="col"><h3 className="result-text">VÉGÖSSZEG:</h3></div>
                                    <div className="col"><h3 className="result-cost">4300 HUF</h3></div>
                                </div>
                                <div className="d-flex result-btn-wrapper"><div className="btn-container"><button className="btn">TOVÁBB A FIZETÉSHEZ</button></div></div>

                            </div>
                        </div>
                    </div>
                </section>
            </Aux>



        )
    }
}

export default Cart;