


//COMPONENTS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import React, { Component } from 'react';
import Aux from '../../Hook/Auxiliary/index';
import icon_arrow from '../../../img/icons/icon-arrow.svg';


//COMPONENTS END

class Ticket extends Component {
    constructor() {
        super();

    }


    render() {




        return (
            <Aux>

                {/* Title section */}
                <section className="section section--title section-title--generic">
                    <div className="container container-xl">
                        <div className="row section-content__wrapper">
                            <div className="col-12 col-md-6">
                                <h1>Felnőtt jegy</h1>
                                <p>14 év felettieknek.</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* END of Title section */}

                <section className="section section--ticket-details">
                    <div className="container container-xl">
                        <div className="row section-content__wrapper">

                            {/* Date picker */}
                            <div className="ticket-details__column col-12 col-lg-5">

                                <div className="ticket-calendar__wrapper">
                                    <div className="boxed--ticket-calendar boxed boxed--border boxed--shadow boxed--style1 boxed--nopadding">

                                        <div className="box-header bg--dark">
                                            <div className="box-header__month">
                                                <a href="#" className="arrow--back"><img src={icon_arrow} /></a>
                                                <span>Július</span>
                                                <a href="#" className="arrow--forward"><img src={icon_arrow} /></a>
                                            </div>
                                            <div className="box-header__year">
                                                2021
                                            </div>
                                        </div>

                                        <div className="boxed-content">
                                            <div className="box-content__table">
                                                <table className="date-picker-table">
                                                    <thead>
                                                        <tr>
                                                            <td>H</td>
                                                            <td>K</td>
                                                            <td>Sze</td>
                                                            <td>Cs</td>
                                                            <td>P</td>
                                                            <td>Szo</td>
                                                            <td>V</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="prev-month">28</td>
                                                            <td className="prev-month">29</td>
                                                            <td className="prev-month">30</td>
                                                            <td>01</td>
                                                            <td>02</td>
                                                            <td className="status-3">03</td>
                                                            <td>04</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-1">05</td>
                                                            <td>06</td>
                                                            <td>07</td>
                                                            <td className="focused">08</td>
                                                            <td>09</td>
                                                            <td>10</td>
                                                            <td>11</td>
                                                        </tr>
                                                        <tr>
                                                            <td>12</td>
                                                            <td>13</td>
                                                            <td className="status-3">14</td>
                                                            <td className="status-1">15</td>
                                                            <td>16</td>
                                                            <td>17</td>
                                                            <td className="status-3">18</td>
                                                        </tr>
                                                        <tr>
                                                            <td>19</td>
                                                            <td>20</td>
                                                            <td>21</td>
                                                            <td>22</td>
                                                            <td>23</td>
                                                            <td className="status-2">24</td>
                                                            <td className="status-2">25</td>
                                                        </tr>
                                                        <tr>
                                                            <td>26</td>
                                                            <td>27</td>
                                                            <td>28</td>
                                                            <td className="status-1">29</td>
                                                            <td className="status-1"> 30</td>
                                                            <td>31</td>
                                                            <td className="next-month">01</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="ticket-calendar__legend-wrapper">
                                    <span className="legend-status-1">Már csak bizonyos járatokra van hely</span>
                                    <span className="legend-status-2">Online már nem foglalható jegy</span>
                                    <span className="legend-status-3">Nem indul járat</span>
                                </div>
                            </div>
                            {/* END OF Date picker */}

                            <div className="ticket-details__column col-12 col-lg-6 pl-lg-5 align-self-start">


                                {/* Time Picker */}
                                <div className="ticket-num__section ticket-num__wrapper">
                                    <h3>Járat és a jegyek száma</h3>

                                    <div className="ticket-num__time-picker-wrapper">

                                        <form className="ticket-num__time-picker">
                                            <FontAwesomeIcon icon={faChevronDown} className="time-picker__arrow" />

                                            <select>
                                                <option>— Válaszd ki az időpontot! —</option>
                                                <option>Capuccino járat — 11:00</option>
                                                <option>Capuccino járat — 15:00</option>

                                            </select>
                                        </form>
                                    </div>
                                </div>
                                {/* END of Time picker */}

                                {/* Ticket number selector */}
                                <div className="ticket-num__section ticket-num__numselector-wrapper">
                                    <div className="numselector__price-section">
                                        <span className="price-section__title"><h4>Jegyek száma:</h4></span>
                                        <span className="price-section__price">Ár: <b>2.800 Ft</b></span>
                                    </div>

                                    <div className="numselector__number-section">
                                        <span className="number-section__minus">
                                            <FontAwesomeIcon icon={faMinus} />
                                        </span>
                                        <span className="number-section__number">0</span>
                                        <span className="number-section__plus">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </span>

                                        <a href="#">
                                            <button className="number-section__button btn btn--primary btn--compact autowidth">
                                                Kosárba <span className="btn__icon"><FontAwesomeIcon icon={faShoppingCart} /></span>

                                            </button>
                                        </a>
                                    </div>
                                </div>
                                {/* END OF Ticket number selector */}

                                <hr />

                                <div className="ticket-num__section ticket-num__notes-section">

                                    <h5>Fontos!</h5>
                                    <p>A hajó indulása előtt <b>maximum 1 órával</b> foglalhatsz jegyet online. Online vásárolt jegyeddel minimum <b>20 perccel a járat indulása előtt</b> jelenj meg a hajónál. Késés esetén a jegy(ek) árát nem áll módunkban visszatéríteni.</p>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>

                {/* Cart Section */}
                <section className="section section--cart-sum bg--secondary">
                    <div className="container container-xl">
                        <div className="row section-content__wrapper">
                            <div className="col-12">
                                <div className="boxed--cart-sum boxed boxed--border border--secondary boxed--rad-3 boxed--compact boxed--nopadding-all">
                                    <div className="box-header">

                                        <div className="box-header__container">
                                            <div className="box-header__box-title-wrapper">
                                                <span className="box-title__icon">
                                                    <FontAwesomeIcon icon={faShoppingCart} />
                                                </span>
                                                <span className="box-title__title">
                                                    <h2>Kosarad tartalma</h2>
                                                </span>
                                            </div>

                                            <div className="box-header__buttons-wrapper">
                                                <a href="#"><button className="btn btn--compact autowidth">Kosár</button></a>

                                                <a href="#"><button className="btn btn--compact autowidth">Pénztárhoz</button></a>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="boxed-content">
                                        <div className="row justify-content-between align-items-top">

                                            <div className="box-content__description col-12 col-lg-4">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non lacus odio. Praesent hendrerit mauris rutrum, ultricies diam ac, lobortis dui. Proin sapien leo, eleifend vel venenatis eget, blandit nec sem. Aliquam in massa tempor, gravida justo ut, facilisis orci. Vivamus non nisl sit amet urna luctus volutpat. </p>
                                            </div>

                                            <div className="box-content__ticketsum-table-container col-12 col-lg-7">
                                                <div className="ticketsum-table-wrapper">

                                                    <table className="ticketsum-table boxed-content__table table--gridtable">
                                                        <thead>
                                                            <tr>
                                                                <td className="ticketsum__ticket-type-col">Jegy</td>
                                                                <td className="ticketsum__ticket-price-col">Ár</td>
                                                                <td className="ticketsum__ticket-quantity-col">Darab</td>
                                                                <td className="ticketsum__ticket-sum-col">Összesen</td>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td className="ticketsum__ticket-item">
                                                                    <span className="ticket-item__title">Capuccino járat</span>

                                                                    <span className="ticket-item__date">2021. július 12.</span>

                                                                    <ul className="ticket-item__item-list">
                                                                        <li>Felnőtt jegy — 11:00</li>
                                                                    </ul>
                                                                </td>
                                                                <td className="ticketsum-item__price hide-this">3.800 Ft</td>
                                                                <td className="ticketsum-item__quantity">x1</td>
                                                                <td className="ticketsum-item__sum">3.800 Ft</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="ticketsum__ticket-item">
                                                                    <span className="ticket-item__title">Capuccino járat</span>

                                                                    <span className="ticket-item__date">2021. július 12.</span>

                                                                    <ul className="ticket-item__item-list">
                                                                        <li>Felnőtt jegy — 11:00</li>
                                                                    </ul>
                                                                </td>
                                                                <td className="ticketsum-item__price hide-this">3.800 Ft</td>
                                                                <td className="ticketsum-item__quantity">x1</td>
                                                                <td className="ticketsum-item__sum">3.800 Ft</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-footer">
                                        <div className="box-footer__container">
                                            <div className="box-footer__sum-wrapper">
                                                <span className="sum__text">Végösszeg:</span>
                                                <span className="sum__num">3.800 Ft</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </Aux>



        )
    }
}

export default Ticket;