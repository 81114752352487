//COMPONENTS START
import React, { Component } from 'react';
import $ from 'jquery';

//COMPONENTS END

//IMGS START
import kisfaludy_logo_compact_colored from '../../../img/logo/1x/kisfaludy_logo_compact_colored.png';
import kisfaludy_logo_compact_colored_animated from '../../../img/graphic_assets/animated-logo/kf.svg';
import iconCart from '../../../img/icons/icon-cart.svg'
//IMGS END



class Header extends Component {
    constructor() {
        super();

        this.toggleNav = this.toggleNav.bind(this);
    }


    toggleNav() {
        var hamburgerMenu = $(".hamburger-toggle");
        var navBar = $(".nav-container");
        var navBarMenu = $("#menu1");
        var stickyClass = "sticky";
        var menuActive = "active";


        $(hamburgerMenu).click(function () {

            if (!$(navBarMenu).hasClass(menuActive)) {
                $(navBarMenu).addClass(menuActive);
            } else {
                $(navBarMenu).removeClass(menuActive);
            }

            if (!$(navBar).hasClass(stickyClass)) {
                $(navBar).addClass(stickyClass);
            }

        });





    };

    componentDidMount() {
        this.toggleNav()
    }
    render() {




        return (
            <section className="section nav--section">

                <div className="nav-container">

                    <div className="visible-xs visible-sm">

                        <div className="container container-xl">
                            <div className="row justify-content-between">
                                <div className="col-4">

                                    <div className="logo logo--mobile">
                                        <a className="logo logo--mobile mobile-logo" href="/">
                                            <img src={kisfaludy_logo_compact_colored}
                                                alt="Logo of Kisfaludy_promo" />
                                        </a>
                                    </div>

                                </div>

                                <div className="col-3 text-right">
                                    <span href="#" className="hamburger-toggle" data-toggle-classname="#menu1;hidden-sm hidden-xs">
                                        <i className="icon icon--sm stack-interface stack-menu"></i>
                                    </span>
                                </div>
                            </div>

                        </div>

                    </div>


                    <div className="container container-xl">

                        <nav id="menu1" className="menu-container bar bar--sm bar-1 hidden-sm ">
                            <div className="logo-container">

                                <div className="logo logo--center">


                                    <object className="animated-logo-navbar" type="image/svg+xml" data={kisfaludy_logo_compact_colored_animated}>svg</object>


                                </div>

                            </div>
                            <ul className="menu menu--horizontal">
                                <li>
                                    <a className="logo logo--mobile mobile-logo" href="/">
                                        JEGYTÍPUSOK
                                    </a>
                                </li>


                                { /* felűlre a regisztrációs gomb mellé login után <li>
                <a className="logo logo--mobile mobile-logo" href="/">
                           PROFIL
                        </a>
                </li>*/}
                            

                                <li>
                                    <a className="logo logo--mobile mobile-logo" href="/">
                                        PÉNZTÁR
                                    </a>


                                </li>
                                <li className="cart-wrapper">
                                    <span className="cart-icon-container">
                                    <img className="cart-class" src={iconCart}/>
                                    <a className="cart-counter"><span className="cart-text">2</span></a>
                                    </span>
                                   
                                    <a className="logo logo--mobile mobile-logo" href="/">
                                        KOSÁR
                                    </a>
                                </li>


                            </ul>






                        </nav>

                    </div>
                </div>
            </section>
        )
    }
}

export default Header;