import './App.css';


//IMGS START
import flagIcon from '../src/img/icons/flag.png'
//IMGS END

//COMPONENTS START
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import HomePage from '../src/components/Pages/HomePage/index';
import Header from '../src/components/Partials/Header/index';
import Footer from '../src/components/Partials/Footer/index';
import Cart from '../src/components/Pages/Cart/index';
import Checkout from '../src/components/Pages/Checkout/index';
import Aux from '../src/components/Hook/Auxiliary/index';
import Ticket from '../src/components/Pages/Ticket/index'
import Sticky from 'react-sticky-el';

//COMPONENTS END



function App() {
  return (
    <div className="main-container">
      <div className="top-bar">
      <div className="container container-xl top-bar justify-content-between d-flex">
        <div className="email-wrapper">
          <span className="top-bar-email-title">E-MAIL: </span> <span className="top-bar-email">INFO@KISFALUDYGOZOS.HU</span>
        </div>

        <div className="flag-wrapper">
         <img src={flagIcon}/>
        </div>
        </div>
      </div>

      <BrowserRouter>

        <Switch>

          <Route exact path="/">
              <Header />
            <HomePage />
            <Footer />
            <div className="top-bar">
              <div className="container container-xl justify-content-between d-flex bottom-bar">
                <Link className="menu-items-hover" to="/">Jegyfoglalás</Link>
                <Link className="menu-items-hover" to="/ticket">Jegy</Link>
                <Link className="menu-items-hover" to="/checkout">Számlázási adatok</Link>
                <Link className="menu-items-hover" to="/cart">Kosár</Link>
              </div>
            </div>

          </Route>

         <Route path="/ticket">         
              <Header />
            <Ticket />
            <Footer />

            <div className="top-bar">
              <div className="container container-xl justify-content-between d-flex bottom-bar">
                <Link className="menu-items-hover" to="/">Jegyfoglalás</Link>
                <Link className="menu-items-hover" to="/ticket">Jegy</Link>
                <Link className="menu-items-hover" to="/checkout">Számlázási adatok</Link>
                <Link className="menu-items-hover" to="/cart">Kosár</Link>
              </div>
            </div>
          </Route>

          <Route path="/checkout">
              <Header />
            <Checkout />
            <Footer />
            <div className="top-bar">
              <div className="container container-xl justify-content-between d-flex bottom-bar">
                <Link className="menu-items-hover" to="/">Jegyfoglalás</Link>
                <Link className="menu-items-hover" to="/ticket">Jegy</Link>
                <Link className="menu-items-hover" to="/checkout">Számlázási adatok</Link>
                <Link className="menu-items-hover" to="/cart">Kosár</Link>
              </div>
            </div>
          </Route>

          <Route path="/cart">
              <Header />
            <Cart />
            <Footer />

            <div className="top-bar">
              <div className="container container-xl justify-content-between d-flex bottom-bar">
                <Link className="menu-items-hover" to="/">Jegyfoglalás</Link>
                <Link className="menu-items-hover" to="/ticket">Jegy</Link>
                <Link className="menu-items-hover" to="/checkout">Számlázási adatok</Link>
                <Link className="menu-items-hover" to="/cart">Kosár</Link>
              </div>
            </div>
          </Route>


        </Switch>
      </BrowserRouter>

    </div>
  );
}

export default App;
