


//COMPONENTS

import React, { Component } from 'react';
import Aux from '../../Hook/Auxiliary/index';

import capuccino_icon from '../../../img/icons/icon-coffee_white.svg';
import lemonade_icon from '../../../img/icons/icon-lemonade_white.svg';
import cocktail_icon from '../../../img/icons/icon-cocktail_white.svg';
import wine_icon from '../../../img/icons/icon-wine_white.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';


//COMPONENTS END

class HomePage extends Component {
    constructor() {
        super();

    }


    render() {




        return (
            <Aux>
                {/* Title section */}
                <section className="section section--title section-title--generic">
                    <div className="container container-xl">
                        <div className="row section-content__wrapper">
                            <div className="col-12 col-md-6">
                                <h1>Jegyfoglalás</h1>
                                <p>Foglald le tetszőleges járatunkra a neked megfelelő jegyet és fizess beépített <b>SimplePay</b> rendszerünkön keresztül! Tájékoztatunk, hogy a <b>maximálisan foglalható jegyek száma 15 db</b>.</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* END of Title section */}

                <section className="section section--cruise-types">
                    <div className="container container-xl">
                        <div className="row section-content__wrapper">
                            <div className="col-12">

                                {/* Cruise type item -- Capuccino */}
                                <div className="cruise-types__item boxed boxed--cruise-type boxed--border boxed--shadow boxed--style1">

                                    {/* header */}
                                    <div className="box-header bg--dark">
                                        <div className="box-header__left-col">
                                            <span className="cruise-type__title">
                                                <img className="icon icon-xl opacity-100" src={capuccino_icon} />
                                                <h3>Capuccino járat</h3>
                                            </span>
                                            <span className="cruise-type__description">
                                                Minden kávéra és meleg teára <b>30% kedvezményt</b> biztosítunk.
                                            </span>
                                        </div>

                                        <div className="box-header__right-col">
                                            <span className="cruise-type__timetable">
                                                <a href="#"><FontAwesomeIcon icon={faCalendarAlt} /> Menetrend</a>
                                            </span>
                                        </div>


                                    </div>

                                    {/* content */}
                                    <div className="boxed-content">
                                        <table className="boxed-content__table table--gridtable">
                                            <thead>
                                                <tr className="table__head">
                                                    <td className="table__ticket-type-col">Jegytípus</td>
                                                    <td className="table__ticket-price-col">Jegyár</td>
                                                    <td className="table__btn-col"></td>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {/* Felnőtt */}
                                                <tr>
                                                    <td className="table-cell__title table__ticket-type-col">Felnőtt</td>
                                                    <td className="table-cell__price">2.800 Ft</td>
                                                    <td className="table-cell__btn">
                                                    <a href="#"><button className="btn btn--primary btn--compact autowidth">Kiválaszt</button></a>
                                                    </td>

                                                </tr>

                                                {/* Gyermek */}
                                                <tr>
                                                    <td className="table-cell__title table__ticket-type-col">Gyermek
                                                        <span className="table-cell__note">4-14 év között</span>
                                                    </td>
                                                    <td className="table-cell__price">1.800 Ft</td>
                                                    <td className="table-cell__btn">
                                                    <a href="#"><button className="btn btn--primary btn--compact autowidth">Kiválaszt</button></a>
                                                    </td>
                                                </tr>

                                                {/* Családi */}
                                                <tr>
                                                    <td className="table-cell__title table__ticket-type-col">Családi (2+2)
                                                        <span className="table-cell__note">Minden további gyermek: <b>+1.000 Ft</b></span>
                                                    </td>
                                                    <td className="table-cell__price">1.800 Ft</td>
                                                    <td className="table-cell__btn">
                                                    <a href="#"><button className="btn btn--primary btn--compact autowidth">Kiválaszt</button></a>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    {/* END OF content */}
                                </div>
                                {/* END of Cruise type item */}

                                {/* Cruise type item -- Hűsítő */}
                                <div className="cruise-types__item boxed boxed--cruise-type boxed--border boxed--shadow boxed--style1">

                                    {/* header */}
                                    <div className="box-header bg--dark">
                                        <div className="box-header__left-col">
                                            <span className="cruise-type__title">
                                                <img className="icon icon-xl opacity-100" src={lemonade_icon} />
                                                <h3>Hűsítő járat</h3>
                                            </span>
                                            <span className="cruise-type__description">
                                                Minden limonádéra, jeges teára és szörpre <b>30% kedvezményt</b> biztosítunk
                                            </span>
                                        </div>

                                        <div className="box-header__right-col">
                                            <span className="cruise-type__timetable">
                                                <a href="#"><FontAwesomeIcon icon={faCalendarAlt} /> Menetrend</a>
                                            </span>
                                        </div>


                                    </div>

                                    {/* content */}
                                    <div className="boxed-content">
                                        <table className="boxed-content__table table--gridtable">
                                            <thead>
                                                <tr className="table__head">
                                                    <td className="table__ticket-type-col">Jegytípus</td>
                                                    <td className="table__ticket-price-col">Jegyár</td>
                                                    <td className="table__btn-col"></td>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {/* Felnőtt */}
                                                <tr>
                                                    <td className="table-cell__title table__ticket-type-col">Felnőtt</td>
                                                    <td className="table-cell__price">2.800 Ft</td>
                                                    <td className="table-cell__btn">
                                                    <a href="#"><button className="btn btn--primary btn--compact autowidth">Kiválaszt</button></a>
                                                    </td>

                                                </tr>

                                                {/* Gyermek */}
                                                <tr>
                                                    <td className="table-cell__title table__ticket-type-col">Gyermek
                                                        <span className="table-cell__note">4-14 év között</span>
                                                    </td>
                                                    <td className="table-cell__price">1.800 Ft</td>
                                                    <td className="table-cell__btn">
                                                    <a href="#"><button className="btn btn--primary btn--compact autowidth">Kiválaszt</button></a>
                                                    </td>
                                                </tr>

                                                {/* Családi */}
                                                <tr>
                                                    <td className="table-cell__title table__ticket-type-col">Családi (2+2)
                                                        <span className="table-cell__note">Minden további gyermek: <b>+1.000 Ft</b></span>
                                                    </td>
                                                    <td className="table-cell__price">1.800 Ft</td>
                                                    <td className="table-cell__btn">
                                                    <a href="#"><button className="btn btn--primary btn--compact autowidth">Kiválaszt</button></a>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    {/* END OF content */}
                                </div>
                                {/* END of Cruise type item */}

                                {/* Cruise type item -- Koktél */}
                                <div className="cruise-types__item boxed boxed--cruise-type boxed--border boxed--shadow boxed--style1">

                                    {/* header */}
                                    <div className="box-header bg--dark">
                                        <div className="box-header__left-col">
                                            <span className="cruise-type__title">
                                                <img className="icon icon-xl opacity-100" src={cocktail_icon} />
                                                <h3>Koktél járat</h3>
                                            </span>
                                            <span className="cruise-type__description">
                                                Minden koktélra <b>30% kedvezményt</b> biztosítunk
                                            </span>
                                        </div>

                                        <div className="box-header__right-col">
                                            <span className="cruise-type__timetable">
                                                <a href="#"><FontAwesomeIcon icon={faCalendarAlt} /> Menetrend</a>
                                            </span>
                                        </div>
                                    </div>

                                    {/* content */}
                                    <div className="boxed-content">
                                        <table className="boxed-content__table table--gridtable">
                                            <thead>
                                                <tr className="table__head">
                                                    <td className="table__ticket-type-col">Jegytípus</td>
                                                    <td className="table__ticket-price-col">Jegyár</td>
                                                    <td className="table__btn-col"></td>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {/* Felnőtt */}
                                                <tr>
                                                    <td className="table-cell__title table__ticket-type-col">Felnőtt</td>
                                                    <td className="table-cell__price">2.800 Ft</td>
                                                    <td className="table-cell__btn">
                                                    <a href="#"><button className="btn btn--primary btn--compact autowidth">Kiválaszt</button></a>
                                                    </td>

                                                </tr>

                                                {/* Gyermek */}
                                                <tr>
                                                    <td className="table-cell__title table__ticket-type-col">Gyermek
                                                        <span className="table-cell__note">4-14 év között</span>
                                                    </td>
                                                    <td className="table-cell__price">1.800 Ft</td>
                                                    <td className="table-cell__btn">
                                                        <a href="#"><button className="btn btn--primary btn--compact autowidth">Kiválaszt</button></a>
                                                        
                                                    </td>
                                                </tr>

                                                {/* Családi */}
                                                <tr>
                                                    <td className="table-cell__title table__ticket-type-col">Családi (2+2)
                                                        <span className="table-cell__note">Minden további gyermek: <b>+1.000 Ft</b></span>
                                                    </td>
                                                    <td className="table-cell__price">1.800 Ft</td>
                                                    <td className="table-cell__btn">
                                                    <a href="#"><button className="btn btn--primary btn--compact autowidth">Kiválaszt</button></a>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    {/* END OF content */}
                                </div>
                                {/* END of Cruise type item */}

                                {/* Cruise type item -- Borjárat */}
                                <div className="cruise-types__item boxed boxed--cruise-type boxed--border boxed--shadow boxed--style1">

                                    {/* header */}
                                    <div className="box-header bg--dark">
                                        <div className="box-header__left-col">
                                            <span className="cruise-type__title">
                                                <img className="icon icon-xl opacity-100" src={wine_icon} />
                                                <h3>Borjárat</h3>
                                            </span>
                                            <span className="cruise-type__description">
                                                <b>1 dl borra</b> vagy egy <b>jeges teára</b> a vendégünk vagy
                                            </span>
                                        </div>

                                        <div className="box-header__right-col">
                                            <span className="cruise-type__timetable">
                                                <a href="#"><FontAwesomeIcon icon={faCalendarAlt} /> Menetrend</a>
                                            </span>
                                        </div>
                                    </div>

                                    {/* content */}
                                    <div className="boxed-content">
                                        <table className="boxed-content__table table--gridtable">
                                            <thead>
                                                <tr className="table__head">
                                                    <td className="table__ticket-type-col">Jegytípus</td>
                                                    <td className="table__ticket-price-col">Jegyár</td>
                                                    <td className="table__btn-col"></td>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {/* Felnőtt */}
                                                <tr>
                                                    <td className="table-cell__title table__ticket-type-col">Borjegy
                                                        <span className="table-cell__note">Kizárólag 18 év felett</span></td>
                                                    <td className="table-cell__price">3.000 Ft</td>
                                                    <td className="table-cell__btn">
                                                    <a href="#"><button className="btn btn--primary btn--compact autowidth">Kiválaszt</button></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* END of Cruise type item */}

                            </div>
                        </div>
                    </div>
                </section>
            </Aux>



        )
    }
}

export default HomePage;